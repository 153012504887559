import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalRecuperarPageRoutingModule } from './modal-recuperar-routing.module';

import { ModalRecuperarPage } from './modal-recuperar.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalRecuperarPageRoutingModule
  ],
  exports: [
    ModalRecuperarPage
  ],
  declarations: [ModalRecuperarPage]
})
export class ModalRecuperarPageModule {}
