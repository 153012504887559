import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ModalRecuperarPage } from './modal-recuperar.page';

const routes: Routes = [
  {
    path: '',
    component: ModalRecuperarPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalRecuperarPageRoutingModule {}
