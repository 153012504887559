import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import { Preferences } from '@capacitor/preferences';

const TOKEN_KEY = 'my-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // Init with null to filter out the first value in a guard!
	isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	token = '';

  constructor( private http: HttpClient ) { this.loadToken(); }

  async loadToken() {
		const token = await Preferences.get({ key: TOKEN_KEY });
		if (token && token.value) {
			//console.log('set token: ', token.value);
			this.token = token.value;
			this.isAuthenticated.next(true);
		} else {
			this.isAuthenticated.next(false);
		}
	}

  login(tokenr:any): Promise<void> {
    const token = tokenr;
    this.isAuthenticated.next(true);
    return Preferences.set({key: TOKEN_KEY, value: token});
		/*return this.http.post(`https://reqres.in/api/login`, credentials).pipe(
			map((data: any) => data.token),
			switchMap((token) => {
				return from(Preferences.set({ key: TOKEN_KEY, value: token }));
			}),
			tap((_) => {
				
			})
		);*/
	}

  logout(): Promise<void> {
		this.isAuthenticated.next(false);
		return Preferences.remove({ key: TOKEN_KEY });
	}
}
